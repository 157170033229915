




















import Vue from 'vue';
import { ResizeObserver } from 'vue-resize'
import Component from 'vue-class-component'
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import { Prop } from 'vue-property-decorator';

@Component({
  components: { SygniRoundedButton, ResizeObserver },
})
export default class PostBox extends Vue {
  @Prop({default: false}) featured: boolean;
  @Prop({}) details: any;

  maxLines: number = 0;

  get detailsTag() {
    const details = Array.isArray(this.details?.tags) ? this.details?.tags : Object.values(this.details?.tags)
    return details?.join(', ') || '';
  }
}
