var render = function () {
  var _vm$details;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('article', {
    class: ['m-post-box', _vm.featured ? 'm-post-box--featured' : '']
  }, [_c('div', {
    staticClass: "m-post-box__featured-image"
  }, [_c('img', {
    attrs: {
      "src": _vm.details.photoHeader,
      "alt": _vm.details.title
    }
  })]), _c('div', {
    staticClass: "m-post-box__content"
  }, [_c('h2', {
    staticClass: "m-post-box__title"
  }, [_vm._v(_vm._s(_vm._f("trimWords")((_vm$details = _vm.details) === null || _vm$details === void 0 ? void 0 : _vm$details.title, 10)))]), _c('p', {
    ref: "postBoxDesc",
    staticClass: "m-post-box__description"
  }, [_vm._v(" " + _vm._s(_vm._f("trimWords")(_vm.details.excerpt, 50)) + " ")])]), _c('div', {
    staticClass: "m-post-box__footer"
  }, [_c('p', {
    staticClass: "m-post-box__category"
  }, [_vm._v(_vm._s(_vm.detailsTag))]), _c('sygni-rounded-button', {
    staticClass: "m-post-box__link outline gn-primary"
  }, [_vm._v("Read more")])], 1), _c('router-link', {
    staticClass: "m-post-box__anchor",
    attrs: {
      "to": {
        name: 'insight-by-slug',
        params: {
          slug: _vm.details.slug
        }
      }
    }
  }, [_vm._v("Read more")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }